import React, {Fragment, useState} from "react";
import SnackbarUtil from '@/utils/SnackbarUtil';
import {useTranslation} from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import DeviceService from '@/services/dms/DeviceService';
import LoadingButton from '@mui/lab/LoadingButton';
import {Delete} from "@mui/icons-material"
import ActionButton from "@/components/actions/ActionButton";

const punchRecordStyle = {
  '& .MuiPaper-root': {width: '400px', top: '-10vh'},
  '.MuiDialog-paper': {maxWidth: '60vw'},
};

export interface DeviceIdsProps {
  deviceIds: any,
}

const DeleteDaylightSavingTime = (props: DeviceIdsProps) => {
  const [info, setInfo] = useState<boolean>(false)
  const {t} = useTranslation()
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (props.deviceIds.length == 0) {
          SnackbarUtil.error(t("common.Select device first"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
          return;
        }
        setVisible(open);
        setInfo(true);
      };

  const DeleteSaveDaylightTime = () => {
    if (props.deviceIds) {
      props.deviceIds.map((dev: any) => {
          DeviceService.deviceInfo(dev).then((res: any) => {
            const data_info = res.data
            if (data_info?.status === '0') {
              SnackbarUtil.error(t("common.Device is offline"), {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                }
              })
              return;
            }
            const data = {
              siteId: data_info?.site,
              dstKey: "",
            }
            DeviceService.DeleteDaylightSavingTime(dev, data).then(
              (result) => {
                SnackbarUtil.success('Success.')
              }
            );
          })
        }
      )
    }
    setInfo(false)
  }

  return (
    <Fragment>
      <Grid sx={{
        display: 'flex',
        alignItems: 'center',
      }} item={true} onClick={toggleDrawer(true)}>
        <ActionButton icon='icon-ic_delete' handleClick={() => {
          // setInfo(true)
        }} title={t("common.Delete Daylight Saving Time")}></ActionButton>
      </Grid>
      <Dialog
        open={info}
        onClose={() => {
          setInfo(false);
        }}
        sx={punchRecordStyle}
      >
        <DialogTitle>
          {t("common.Delete Daylight Saving Time")}
        </DialogTitle>
        <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
          <Typography>
            {t("common.are you sure to delete these devices daylight saving time?")}
          </Typography>
          <DialogActions>
            <LoadingButton
              id='ConfirmButton'
              loading={loading}
              sx={{textTransform: "capitalize"}}
              onClick={() => (DeleteSaveDaylightTime())}
              variant={"contained"}>{t('common.confirm')}</LoadingButton>
            <Button id='CancelButton' sx={{textTransform: "capitalize"}} onClick={() => {
              setInfo(false);
            }}>{t('common.cancel')}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default DeleteDaylightSavingTime;
