import React, { useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import { Grid, Paper, Stack, Drawer } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import EmployeeService from "@/services/hr/EmployeeService";
import { CancelButton } from "@/components/data-grid/CustomButton";
import EmployeeForm from "../../Employee/components/EmployeeForm";

const ResignedEmployeeView = (props: any, ref: any) => {
  const { t } = useTranslation();
  const employeeValidate = object({
    code: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    departmentIdOrCode: z.string().optional(),
    dept: z.object({
      id: z.string(),
      name: z.string(),
    }),
  })
  type employeeInput = TypeOf<typeof employeeValidate>;
  const employeeForm= useForm<employeeInput>({
    resolver: zodResolver(employeeValidate),
    defaultValues: {
      dept: {id: '', name: ''},
    }
  });

  const [state, setState] = useState({ id: ""}) // 编辑离职人员id值
  const [open, setOpen] = useState(false)
  const onClose = () => {
    employeeForm.reset()
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showDetail: async (id:string) => {
      setState({id})
      setOpen(true)
    }
  }));
  useEffect(() => {
    if (state.id) {
      EmployeeService.retrieve(state.id || "").then((res) => {
        const emp = res.data.data
        employeeForm.reset({
          code: emp?.employeeCode,
          firstName: emp?.firstName,
          lastName: emp?.lastName,
          email: emp?.email,
          departmentIdOrCode: emp?.departmentIdOrCode,
          dept: {id: emp?.departmentIdOrCode, name: emp?.department_name}
        })
      })
    }
  }, [state]);

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Grid sx={{
        minWidth: '400px',
        minHeight: '100vh',
        display:'flex',
        flexDirection:'column',
        justifyContent: 'flex-start',
        position: 'relative',
      }}>
        <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '0px 24px',
          borderBottom: '1px solid #F2F5F8',
        }}>
          <Grid>{ t("hr.resignation.View Person") }</Grid>
          <Grid>
            <i style={{ cursor: "pointer" }} className="icon iconfont icon-ic_close" onClick={onClose}></i>
          </Grid>
        </Grid>
        <EmployeeForm employeeForm={employeeForm} isViewAction={true}/>
        <Grid sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
          <Grid style={{paddingTop: '12px', paddingLeft: '24px'}}>
            <Stack spacing={3} direction={"row"} justifyContent="start" >
              <CancelButton onClick={onClose} />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}
export default forwardRef(ResignedEmployeeView);
