export const ChangeLog = [
  // "修复一些已知的问题。",
  // "优化已知的UI问题。",
  // "添加加班计算的功能，并在报表显示。",
  // "支持原始考勤记录的导出。",
  // "支持补签卡/未使用的时间表/班次的编辑与删除。",
  // "设备的接口升级。",
  // "排班与原始考勤记录新增时区字段的显示。",
  // "离线设备不支持下发命令操作。",
  // "登录时连续输入五次错误密码将锁定账号5分钟。",
  "Fix some known issues.",
  "Optimize known UI issues.",
  "Add the function of overtime calculation and display it in the report.",
  "Supports exporting original attendance records.",
  "Support editing and deleting supplementary cards/unused schedules/shifts.",
  // "Upgrade the interface of the device.",
  "Display of time zone fields added to scheduling and original attendance records.",
  "Offline devices do not support issuing command operations.",
  "Entering the wrong password five times in a row during login will lock the account for 5 minutes.",
  "Support disabling the automatic export function",
  "Schedule module adds manual calculation function",
  "Support viewing update logs for this version",
]

export const UpdateDate = '2025.01.21'
