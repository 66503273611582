import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {Box, styled} from "@mui/system";
import {
  TextField,
  Grid,
  Paper,
  Typography,
  Avatar,
  Button,
  Dialog,
  DialogContent, DialogTitle, DialogActions,
} from "@mui/material";
import {DMSEndpoints} from "@/services/dms/endpoints";
import GenericService from "../../../../services/GenericService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import WebURL from "@/urls";
import {useTranslation} from "react-i18next";
import BackGo from "@/components/back-go/BackGo";
import {LoadingButton} from "@mui/lab";
import DeviceService from "@/services/dms/DeviceService";
import SnackbarUtil from "@/utils/SnackbarUtil";


const dialogStyle = {
  '& .MuiPaper-root': {width: '700px', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': {maxWidth: '100vw'},
  overflowX: "hidden",
};

type DeviceProps = {
  sn: string;
  alias: string;
  site: string;
  zone: string;
  site_name?: string;
  // zone_name?: string;
  organization_name?: string;
  timezone?: string;
  ipv4?: string;
  MCUVersion?: string;
  firmware_ver?: string;
  model: string;
  category: string;
  protocol_type: string;
  status: string;
  parameters: {
    ip?: string;
    userCount?: string;
    fpCount?: string;
    visLightFaceCount?: string;
  };
};

type InputItemProps = {
  title: string;
  value: any;
  tip?: string;
  edit?: boolean;
  disabled?: boolean;
};

const InputItem: React.FC<InputItemProps> = (props) => {
  let textValue = "";
  if (typeof props.value != "undefined") textValue = props.value;
  return (
    <Box display="row" paddingBottom={2}>
      <Typography>{props.title}</Typography>
      <TextField
        disabled={true}
        value={textValue}
        sx={{width: "calc(100% - 10px)"}}
      />
    </Box>
  );
};

const EditInputItem: React.FC<InputItemProps> = (props) => {
  const [val, setVal] = React.useState("");
  useEffect(() => {
    setVal(props.value);
  }, [props]);
  return (
    <Box display="row" paddingBottom={2}>
      <Typography sx={{paddingBottom: "12px"}}>{props.title}</Typography>
      <TextField
        disabled={props.disabled}
        sx={{width: "calc(100% - 10px)"}}
        value={val}
        onChange={(event) => setVal(event.target.value)}
      />
    </Box>
  );
};

EditInputItem.defaultProps = {
  disabled: true,
};

const DeviceView: React.FC = () => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [upgradeOpen, setUpgradeOpen] = React.useState(false);
  const taskId = useRef("")
  const [stopTask, setStopTask] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const state = location.state as { id: string };
  const [device, setDevice] = useState<DeviceProps>();
  const [lastVer, setLastVer] = React.useState<any>(device?.firmware_ver);
  const [upgradeButton, setUpgradeButton] = React.useState<boolean>(false);
  const [statusTitle, setStatusTitle] = React.useState<string>(t("common.OTA package downloading"));
  const [statusContent, setStatusContent] = React.useState<string>(t("common.OTA package downloading, please wait"));
  const [progress, setProgress] = useState<any>(0.00)
  const timeout = useRef<number>(1)
  const cur_progress = useRef<any>(0.00)
  useEffect(() => {
    GenericService.retrieve(DMSEndpoints.DMS_DEVICE_URI, state?.id || "").then(
      (res) => {
        setDevice(res.data);
        DeviceService.GetDeviceFirmware(state?.id || "").then((fr) => {
          if (fr.data?.data?.firmwareInfo) {
            const firmware_info = fr.data?.data.firmwareInfo
            if (firmware_info) {
              setLastVer(firmware_info?.latestVersion)
            }
            if (res.data?.firmware_ver !== firmware_info?.latestVersion) {
              if (res.data?.status !== '0') {
                setUpgradeButton(true)
                setOpen(true)
              } else {
                setOpen(false)
                setUpgradeButton(false)
              }
            } else {
              setUpgradeButton(false)
            }
          } else {
            setOpen(false)
            setUpgradeButton(false)
          }
        })
      }
    );
  }, []);
  const upgradeOTA = () => {
    taskId.current = ''
    setOpen(false)
    const data = {
      "deviceId": state?.id || "",
      "version": lastVer
    }
    DeviceService.UpgradeDeviceFirmware(data).then((res) => {
      if (res.data) {
        taskId.current = res.data?.taskId
        setUpgradeOpen(true)
        setStopTask(false)
      } else {
        SnackbarUtil.error(res.data?.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
      }
    })
  }
  const fetchData = async () => {
    try {
      if (!taskId.current) {
        setStopTask(true)
        return
      }
      DeviceService.GetUpgradeTask(taskId.current).then((res) => {
        const data = res.data;
        // 0（升级包下载中） 1(设备升级中) 2(设备升级成功) -1(设备升级失败)
        console.log("获取更新任务的信息：", data)
        if (data && data?.data) {
          if (timeout.current > 60) {
            timeout.current = 1
            setStopTask(true)
            setStatusTitle(t("common.OTA upgrade failure"))
            setStatusContent(t("common.OTA upgrade failure, please try again later"))
            setProgress(0.00)
            cur_progress.current = 0.00
          } else {
            if (data?.data.state == '-1') {
              setStopTask(true)
              setStatusTitle(t("common.OTA upgrade failure"))
              setStatusContent(t("common.OTA upgrade failure, please try again later"))
              setProgress(0.00)
            } else if (data?.data.state == '0') {
              console.log('download timeout count:', timeout.current)
              if (!data?.data.percent) {
                timeout.current += 1
                setProgress(0.00)
                cur_progress.current = 0.00
              } else if (data?.data.percent == '100.00') {
                timeout.current = 1
                setProgress(0.00)
                cur_progress.current = 0.00
              } else {
                if (cur_progress.current == parseFloat(data?.data.percent)) {
                  timeout.current += 1
                } else {
                  timeout.current = 1
                  setProgress(parseFloat(data?.data.percent))
                  cur_progress.current = parseFloat(data?.data.percent)
                }
                setStatusTitle(t("common.OTA package downloading"))
                setStatusContent(t("common.OTA package downloading, please wait"))
              }
            } else if (data?.data.state == '1') {
              console.log('upgrading timeout count:', timeout.current)
              if (!data?.data.percent) {
                timeout.current += 1
                setProgress(0.00)
              } else if (data?.data.percent == '100') {
                if (cur_progress.current == 50) {
                  timeout.current += 1
                } else {
                  setProgress(50)
                  cur_progress.current = 50
                }
              } else {
                setProgress(parseFloat(data?.data.percent))
              }
              setStatusTitle(t("common.OTA upgrading"))
              setStatusContent(t("common.OTA upgrading, please wait"))
            } else if (data?.data.state == '2') {
              setStopTask(true)
              setStatusTitle(t("common.OTA upgrade successful"))
              setStatusContent(t("common.OTA upgrade successful, please wait"))
              setProgress(100)
              cur_progress.current = 0.00
              timeout.current = 0
            } else {
              setStopTask(true)
              setStatusTitle(data?.data.stateMessage)
              setStatusContent(data?.data.stateMessage)
              setProgress(0.00)
              cur_progress.current = 0.00
              timeout.current = 1
            }
          }
        }
      })
    } catch (err) {
      timeout.current = 1
      setStopTask(true)
      setStatusTitle(t("common.OTA upgrade failure"))
      setStatusContent(t("common.OTA upgrade failure, please try again later"))
      setProgress(0.00)
      cur_progress.current = 0.00
      // setError(err.message); // 设置错误信息
    } finally {
      setLoading(true); // 更新加载状态
    }
  };

  // 使用 useEffect 设置定时器
  useEffect(() => {
    // fetchData(); // 组件挂载时立即调用一次API
    if (!stopTask) {
      const intervalId = setInterval(fetchData, 1000); // 每秒钟调用一次API获取进度
      // 组件卸载时清除定时
      return () => clearInterval(intervalId);
    }
    setLoading(false)
  }, [stopTask]);
  return (
    <>
      <CustomBreadcrumbs record={[
        // { path: WebURL.DMS_DEVICE, label: t("common.Device") },
        {label: t("common.Device detail")},
      ]}/>

      <Grid
        sx={{
          boxSizing: "border-box",
          marginRight: "24px",
        }}
      >
        <BackGo title={t("common.Device detail")}></BackGo>

        <Grid
          sx={{
            display: "flex",
            height: "80px",
            marginTop: "16px",
            backgroundColor: "#ffffff",
            marginBottom: "16px",
            padding: "12px",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <Avatar
            src={"/images/device/deviceIcon.png"}
            sx={{width: 56, height: 56}}
            variant="rounded"
          ></Avatar>

          <Box
            sx={{
              marginLeft: "14px",
            }}
          >
            <Box>
              <Typography
                style={{
                  color: "#3D3D3D",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {device?.model}
              </Typography>
            </Box>
            <Box>
              <Typography
                style={{
                  color: "#919DA1",
                  fontSize: "12px",
                }}
              >
                SN: {device?.sn}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid
        sx={{
          padding: "20px",
          boxSizing: "border-box",
          marginRight: "24px",
          backgroundColor: "#ffffff",
        }}
      >
        <Paper
          elevation={0}
          style={{
            minHeight: "200px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            paddingTop: "16px",
          }}
        >
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <InputItem
                title={t("common.Device Alias")}
                value={device?.alias}
              />
            </Grid>
            <Grid item xs={4}>
              <InputItem
                title={t("common.IP Address")}
                value={device?.ipv4}
              />
            </Grid>
            <Grid item xs={4}>
              <InputItem
                title={t("dms.User Qty.")}
                value={device?.parameters?.userCount}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <InputItem
                title={t("dms.Face Qty.")}
                value={device?.parameters?.visLightFaceCount}
              />
            </Grid>
            <Grid item xs={4}>
              <InputItem
                title={t("dms.Fingerprint Qty.")}
                value={device?.parameters?.fpCount}
              />
            </Grid>
            <Grid item xs={4}>
              <InputItem
                title={t("dms.Status")}
                value={
                  device?.status === "1" ? t("dms.Online") : t("dms.Offline")
                }
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <InputItem
                title={t("common.Bind Organization")}
                value={device?.organization_name}
              />
            </Grid>
            <Grid item xs={4}>
              <InputItem title={t("dms.Bind Site")} value={device?.site_name}/>
            </Grid>
            {/*<Grid item xs={4}>*/}
            {/*  <InputItem title={t("dms.Bind Zone")} value={device?.zone_name} />*/}
            {/*</Grid>*/}
          </Grid>
        </Paper>
        <Box style={{padding: "10px 0 10px 0"}}>
          <Typography variant='h6'>{t("common.Firmware Information")}</Typography>
        </Box>
        <Paper
          elevation={0}
          style={{
            minHeight: "120px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            paddingTop: "16px",
          }}
        >
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <InputItem
                title={t("common.Protocol Type")}
                value={device?.protocol_type}
              />
            </Grid>
            <Grid item xs={4}>
              <InputItem
                title={t("common.Firmware Version")}
                value={device?.firmware_ver}
              />
            </Grid>
            {upgradeButton && <Grid item xs={4}>
              <Button
                sx={{
                  backgroundColor: "#2BA9DE",
                  color: "#ffffff",
                  height: "40px",
                  top: "25px",
                  borderRadius: "4px",
                  "&:hover": {
                    background: " #2BA9DE",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  setOpen(true)
                  setStatusTitle(t("common.OTA package downloading"))
                  setStatusContent(t("common.OTA package downloading, please wait"))
                }}>
                {t("common.Firmware Upgrade")}
              </Button>
            </Grid>}
            {open && <Dialog
              open={true}
              sx={{dialogStyle}}
            >
              <DialogTitle>
                {t("common.OTA remote upgrade")}
              </DialogTitle>
              <DialogContent sx={{lineHeight: '25px', minWidth: "450px", color: 'gray'}}>
                <Grid item sx={{marginTop: "10px"}}>
                  <Grid>
                    <Box display="row" paddingBottom={2}>
                      <Typography sx={{marginRight: "50px"}}>{t("common.Current Firmware Version")}</Typography>
                      <TextField
                        disabled={true}
                        value={device?.firmware_ver}
                        sx={{width: "calc(100% - 10px)"}}
                      />
                    </Box>
                  </Grid>
                  <Grid sx={{marginTop: "10px"}}>
                    <Box display="row" paddingBottom={2}>
                      <Typography sx={{marginRight: "50px"}}>{t("common.Latest Firmware Version")}</Typography>
                      <TextField
                        disabled={true}
                        value={lastVer}
                        sx={{width: "calc(100% - 10px)"}}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button sx={{textTransform: "capitalize"}} onClick={() => {
                    setOpen(false)
                  }}>{t('common.cancel')}</Button>
                  <Button
                    disabled={device?.firmware_ver == lastVer}
                    sx={{textTransform: "capitalize"}}
                    onClick={() => (upgradeOTA())}
                    variant={"contained"}>{t('common.upgrade now')}</Button>
                </DialogActions>
              </DialogContent>
            </Dialog>}
            {upgradeOpen && <Dialog
              open={true}
              sx={{dialogStyle}}
            >
              <DialogTitle>
                {statusTitle}
              </DialogTitle>
              <DialogContent sx={{lineHeight: '25px', color: 'gray', minWidth: "450px"}}>
                <Grid item sx={{marginTop: "10px"}}>
                  <Grid sx={{marginTop: "10px"}}>
                    <Typography>{statusContent}</Typography>
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: '#e0e0e0',
                        borderRadius: '5px',
                        margin: '20px 0',
                      }}
                    >
                      <div
                        style={{
                          width: `${progress}%`,
                          height: '20px',
                          backgroundColor: progress === 100 ? '#4caf50' : '#2BA9DE',
                          borderRadius: '5px',
                          textAlign: 'center',
                          color: 'white',
                          lineHeight: '20px',
                          transition: 'width 0.5s ease', // 平滑过渡
                        }}
                      >
                        {progress}%
                      </div>
                    </div>
                    <Typography>{t("common.upgrade note")}</Typography>
                  </Grid>
                </Grid>
                <DialogActions>
                  <LoadingButton
                    loading={loading}
                    sx={{textTransform: "capitalize"}}
                    onClick={() => {
                      setOpen(false)
                      setUpgradeOpen(false)
                    }}>{t('common.confirm')}</LoadingButton>
                </DialogActions>
              </DialogContent>
            </Dialog>}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
export default DeviceView;
