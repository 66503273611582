import React, {Fragment, useState} from "react";
import SnackbarUtil from '@/utils/SnackbarUtil';
import {useTranslation} from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip, Typography
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import DeviceService from '@/services/dms/DeviceService';
import LoadingButton from '@mui/lab/LoadingButton';
import {ReactComponent as IconDaylightInfo} from '@/assets/icons/Daylight.svg';
import Util from "@/utils/Util";
import ActionButton from "@/components/actions/ActionButton";
import {dst_key} from "@/components/resource/timezone";

function isValidDate(date: any) {
  return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 2000
}

const punchRecordStyle = {
  '& .MuiPaper-root': {width: '400px', top: '-10vh'},
  '.MuiDialog-paper': {maxWidth: '60vw'},
};

export interface DeviceIdsProps {
  deviceIds: any
}

const DaylightSavingTime = (props: DeviceIdsProps) => {
  const [info, setInfo] = useState<boolean>(false)
  const {t} = useTranslation()
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dst_list = dst_key();
  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (props.deviceIds.length == 0) {
          SnackbarUtil.error(t("common.Select device first"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
          return;
        }
        setVisible(open);
        setInfo(true);
      };
  const saveDaylightTime = () => {
    if (props.deviceIds) {
      props.deviceIds.map((dev: any) => {
        DeviceService.deviceInfo(dev).then((res: any) => {
          const data_info = res.data
          if (data_info?.status === '0') {
            SnackbarUtil.error(t("common.Device is offline"), {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            return;
          }
          const dst_id = dst_list.find((item) => item.value === data_info?.timezone)
          if (!dst_id?.name) {
            SnackbarUtil.error(t('common.DST key error'), {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            return
          }
          const data = {
            siteId: data_info?.site,
            dstKey: dst_id?.name || "ngteco",
          }
          DeviceService.DaylightSavingTime(dev, data).then(
            (result) => {
              SnackbarUtil.success('Success.')
            }
          )
        })
      })
    }
    setInfo(false)
  }

  return (
    <Fragment>
      <Grid item={true} onClick={toggleDrawer(true)}>
        <ActionButton title={t("common.Daylight Saving Time")} handleClick={() => {
          // setInfo(true)
        }} icon="icon-a-ic_daylightsavingtime"></ActionButton>
      </Grid>
      <Dialog
        open={info}
        onClose={() => {
          setInfo(false);
        }}
        sx={punchRecordStyle}
      >
        <DialogTitle>
          {t("common.Daylight Saving Time")}
        </DialogTitle>
        <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
          <Typography>
            {t("common.are you sure to set these devices daylight saving time?")}
          </Typography>
          <DialogActions>
            <LoadingButton
              id='ConfirmButton'
              loading={loading}
              sx={{textTransform: "capitalize"}}
              onClick={() => (saveDaylightTime())}
              variant={"contained"}>{t('common.confirm')}</LoadingButton>
            <Button id='CancelButton' sx={{textTransform: "capitalize"}} onClick={() => {
              setInfo(false);
            }}>{t('common.cancel')}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default DaylightSavingTime;
