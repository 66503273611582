import React, {useEffect, useImperativeHandle, useState, forwardRef} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {object, TypeOf, z} from "zod";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {
  Grid,
  Stack,
  Drawer,
  Box,
  Button,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {zodResolver} from "@hookform/resolvers/zod";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import Uploady, {useBatchAddListener} from "@rpldy/uploady";
import UploadPreview from "@rpldy/upload-preview";
import {asUploadButton} from "@rpldy/upload-button";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import CompanyService from "@/services/auth/CompanyService";
import {obtainCompanyInfo} from "@/store/slices/App";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {ReactComponent as DefaultOrganization} from '@/assets/images/DefaultOrganization.svg';

const OrganEdit = (props: any, ref: any) => {
  const {t} = useTranslation();
  const creationSchema = object({
    name: z.string().min(1, t("common.This field is required!")),
    code: z.string().min(1, t("common.This field is required!")),
  })
  type creationInput = TypeOf<typeof creationSchema>;
  const organForm = useForm<creationInput>({
    resolver: zodResolver(creationSchema)
  });
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: {errors},
  } = organForm;
  const [oldData, setOldData] = useState<any>();
  const [country, setCountry] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<string>("");
  const company = useSelector((state: any) => state.app.company);
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState<any>();
  const name_text = t("organ.name text tip")
  const code_text = t("organ.code text tip")
  const [loading, setLoading] = useState<boolean>(false);

  const CustomUploadButton = asUploadButton(forwardRef(
    (props: any, ref: any) => {
      useBatchAddListener((batch) => {
        const file_name = batch.items[0].file.name.toLowerCase()
        const format_list = ['.jpeg', '.png', '.jpg']
        if (!format_list.some(item => file_name.includes(item))) {
          enqueueSnackbar(t("common.invalid file format"), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
          setImageFile(undefined)
          setCompanyLogo('')
          return
        }
        if (batch.items[0].file.size > 3000000) {
          enqueueSnackbar(t("common.Photos size up to maximum 3 MB"), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
          setImageFile(undefined)
          setCompanyLogo('')
          return;
        } else {
          setImageFile(batch.items[0].file)
          setCompanyLogo(companyLogo)
        }
      });
      return <Button component="label" variant="contained" sx={{width: '45%', textTransform: "none"}} {...props} />
    }
  ));

  const [open, setOpen] = useState(false)
  const onClose = () => {
    setImageFile(undefined);
    organForm.reset()
    setEdit(false);
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showDetail: () => {
      setEdit(true);
      setOpen(true)
    }
  }));

  const title = t("common.Editor") + ' ' + t("common.Organization Profile")

  useEffect(() => {
    CompanyService.companyInfo().then(res => {
      setImageFile(undefined);
      setCompanyLogo(res.data.imagePreSignedURL)
      setValue("name", res.data.name);
      setValue("code", res.data.code);
      setCountry(res.data?.country || "");
      setOldData({
        "country": res.data?.country || "",
        "name": res.data.name,
        "companyLogo": res.data?.imagePreSignedURL
      })
    });
  }, [edit])

  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();
    const data = {country: country, name: values.name, companyLogo: imageFile, id: company.id, oldData: oldData};
    try {
      const result = await CompanyService.update(data)
      if (result.code == "UASE0060") {
        enqueueSnackbar(result.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        return
      }
      if (result.code == "UASI0007") {
        dispatch<any>(obtainCompanyInfo());
        enqueueSnackbar(t("common.Organization updated successfully."), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        if (props.reloadData) {
          props.reloadData()
        }
        setEdit(false);
        onClose()
      }
    } finally {
      setLoading(false);
    }
  };
  const MyPreview = (props: any) => {
    return (
      <Card sx={{maxWidth: 345}}>
        <CardMedia
          component="img"
          height="200"
          image={props.url}
          alt="Company Logo"
        />
      </Card>
    )
  };

  const ImageView = (props: any) => {
    return (
      <Card sx={{maxWidth: 345}}>
        <CardMedia
          component="img"
          height={props.height}
          image={companyLogo}
          alt="Company Logo"
        />
      </Card>
    )
  };


  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Grid sx={{
        minWidth: '400px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
      }}>
        <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '24px',
          borderBottom: '1px solid #F2F5F8',
        }}>
          <Grid>{title}</Grid>
          <Grid>
            <i style={{cursor: "pointer"}} className="icon iconfont icon-ic_close" onClick={onClose}></i>
          </Grid>
        </Grid>
        <Grid sx={{
          padding: '24px 48px 24px 24px',
        }}>
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #AEAFB0',
            width: '100%',
            height: '300px',
            lineHeight: '50px',
            minWidth: '200px',
            marginBottom: '12px'
          }}>
            <Uploady>
              <Box sx={{height: '200px'}}>
                {
                  imageFile ?
                    <UploadPreview
                      PreviewComponent={MyPreview}
                    />
                    :
                    companyLogo ? <ImageView height="200"/> : <DefaultOrganization/>
                }
              </Box>
              <Box sx={{color: "#999999"}}>
                <Typography>{t("common.Photos size up to maximum 3 MB")}</Typography>
              </Box>
              <CustomUploadButton>{t("organ.Add photos")}</CustomUploadButton>
            </Uploady>
          </Grid>
          <ControlledTextFiled
            hookForm={organForm}
            name={'code'}
            label={t("organ.Organization Code")}
            tooltip={code_text}
            disabled={true}
            fullWidth
            required
          />
          <ControlledTextFiled
            hookForm={organForm}
            name={'name'}
            tooltip={name_text}
            label={t("organ.Organization Name")}
            fullWidth
            required
          />
          <Grid>
            <NGInputLabel label={t("signUp.Choose your country")}/>
            <Select
              sx={{width: '100%'}}
              value={country}
              defaultValue={"USA"}
              onChange={e => {
                setCountry(e.target.value);
              }}
            >
              <MenuItem value={"USA"}>{t("common.America")}</MenuItem>
              <MenuItem value={"JPN"}>{t("common.Japan")}</MenuItem>
              <MenuItem value={"GER"}>{t("common.Germany")}</MenuItem>
              <MenuItem value={"FRA"}>{t("common.France")}</MenuItem>
              <MenuItem value={"SPA"}>{t("common.Spain")}</MenuItem>
              <MenuItem value={"UK"}>{t("common.United Kingdom")}</MenuItem>
              <MenuItem value={"ITA"}>{t("common.Italy")}</MenuItem>
              <MenuItem value={"CAN"}>{t("common.Canada")}</MenuItem>
              <MenuItem value={"MEX"}>{t("common.Mexico")}</MenuItem>
              <MenuItem value={"MAL"}>{t("common.Malaysia")}</MenuItem>
              <MenuItem value={"THA"}>{t("common.Thailand")}</MenuItem>
              <MenuItem value={"PHI"}>{t("common.Philippines")}</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
          <Grid style={{paddingTop: '12px', paddingLeft: '24px'}}>
            <Stack spacing={3} direction={"row"} justifyContent="start">
              <CancelButton onClick={onClose}/>
              <SavingButton loading={loading} onClick={organForm.handleSubmit(onSubmit)}/>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}
export default forwardRef(OrganEdit);
