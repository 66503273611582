import React, {useState, createRef, useEffect} from "react";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Drawer,
  Paper,
} from "@mui/material";
import { Language } from "@mui/icons-material";
import { Box } from "@mui/system";
import TranslationUtil from "@/utils/TranslationUtil";
import { CancelButton, SaveButton, SavingButton } from "@/components/data-grid/CustomButton";
import i18n from "@/config/i18n";
import { useTranslation } from "react-i18next";
import { CountryName } from "@/utils/CountryName";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import { Controller, useForm } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers";
import Util from "@/utils/Util";
import { zodResolver } from "@hookform/resolvers/zod";
import { object, TypeOf, z } from "zod";
import { BreakTimeSettingFields } from "@/pages/att/Timesheet/component/PatternSetting";
import { DropzoneDialog, DropzoneArea } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import request from "../../../utils/Request";
import { AuthEndpoints } from "@/services/auth/endpoints";
import { useSnackbar } from "notistack";
import UserService from "@/services/auth/UserService";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ReactComponent as IconUpload } from "@/assets/icons/IconUpload.svg";
import {useSelector} from "react-redux";
import CompanyService from "@/services/auth/CompanyService";
export type PatternSettingFields = {
  companyName: string;
};

type LanguageMenuItemProps = {
  hidePopup?: () => Promise<void>;
};

const SupportMenuItem: React.FC<LanguageMenuItemProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [getData, setGetData] = useState<boolean>(false);
  const defaultPattern = {
    companyName: "",
    impact: "medium",
    frequency: "seldom",
    contactPerson: "",
    country: "USA",
    emailAddress: "",
    contactNumber: "",
    summary: "",
    description: "",
    steps2reproduce: "",
    // attachment: "",
  };
  const [country, setCountry] = useState<string>("");
  const country_name = CountryName;
  const { enqueueSnackbar } = useSnackbar();
  const patternSchema = object({
    companyName: z.string().min(1, t("common.This field is required!"))
      .max(255, t("common.The length of field cannot exceed digits", {number: 255})),
    impact: z.string(),
    frequency: z.string(),
    contactPerson: z.string().min(1, t("common.This field is required!"))
      .max(255, t("common.The length of field cannot exceed digits", {number: 255})),
    country: z.string().min(1, t("common.This field is required!")),
    emailAddress: z.string().min(1, t("common.This field is required!")).max(250,
      t("common.The length of field cannot exceed digits", {number: 250})),
    contactNumber: z.string().min(1, t("common.This field is required!")).max(50,
      t("common.The length of field cannot exceed digits", {number: 50})),
    summary: z.string().min(1, t("common.This field is required!")).max(255,
      t("common.The length of field cannot exceed digits", {number: 255})),
    description: z.string().min(1, t("common.This field is required!")).max(5000,
      t("common.The length of field cannot exceed digits", {number: 5000})),
    steps2reproduce: z.string().min(1, t("common.This field is required!")).max(5000,
      t("common.The length of field cannot exceed digits", {number: 5000})),
    attachment: z.any(),
  });
  type PatternInput = TypeOf<typeof patternSchema>;

  const patternSettingForm = useForm<PatternInput>({
    defaultValues: defaultPattern,
    resolver: zodResolver(patternSchema),
  });
  const {
    register,
    trigger,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = patternSettingForm;

  const customIcon = (): any => {
    return () => {
      return <IconUpload style={{ marginTop: '60px' }}></IconUpload>;
    };
  };
  useEffect(() => {
    UserService.userInfo().then(user_res => {
      patternSettingForm.setValue('contactPerson', user_res.data?.firstName + ' ' + user_res.data?.lastName)
      patternSettingForm.setValue('emailAddress', user_res.data?.email)
      patternSettingForm.setValue('contactNumber', user_res.data?.phone || '')
      patternSettingForm.setValue('country', user_res.data?.country)
      setCountry(user_res.data?.country)
    });
    CompanyService.companyInfo().then(res => {
      patternSettingForm.setValue('companyName', res.data?.name)
    })
  }, [getData])
  const user = useSelector((state: any) => state.app.user);

  const onSubmit = async (formData: any) => {
    setLoading(true)
    const formdata = new FormData();
    Object.keys(formData).map((key: any) => {
      if (key == "attachment") {
        Object.keys(formData[key]).map((att_key: any, index: number) => {
          formdata.append(
            "attachment" + index.toString(),
            formData[key][index]
          );
        });
      } else {
        formdata.append(key, formData[key]);
      }
    });
    await UserService.supportFeedback(formdata).then((response) => {
      if (response?.status < 400) {
        enqueueSnackbar(t("common.success"), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setOpen(false);
      } else {
        enqueueSnackbar(t("common.Country field error"), {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    });
    setLoading(false);
  };

  const onClose = () => {
    patternSettingForm.reset();
    setOpen(false);
    setGetData(false);
    setLoading(false);
  };

  return (
    <Box>
      <MenuItem
        onClick={async () => {
          await props.hidePopup?.();
          setOpen(true);
          setGetData(true);
        }}
      >
        <ListItemText>{t("common.Contact us")}</ListItemText>
      </MenuItem>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Grid
          sx={{
            minWidth: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            position: "relative",
            height: "100vh",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "56px",
              padding: "0px 24px",
              borderBottom: "1px solid #F2F5F8",
            }}
          >
            <Grid>{t("common.support tips1")}</Grid>
            <Grid>
              <i
                style={{ cursor: "pointer" }}
                className="icon iconfont icon-ic_close"
                onClick={onClose}
              ></i>
            </Grid>
          </Grid>
          <Grid
            sx={{
              flex: 1,
              overflowY: "auto",
              overflowX: "hidden",
              paddingBottom: "24px",
            }}
          >
            <Paper sx={{ marginLeft: "20px", width: "100%", boxShadow: "none" }}>
              <Typography variant="h6" gutterBottom>
                {t("common.support tips2")}
              </Typography>
              {/*<Typography variant="h6" gutterBottom>*/}
              {/*  {t("common.support tips3")}*/}
              {/*</Typography>*/}
            </Paper>
            <Grid
              container={true}
              rowSpacing={1}
              columnSpacing={6}
              component={"form"}
              sx={{ padding: "0 24px" }}
            >
              <Grid item xs={12} sx={{ p: "0 16px" }}>
                <Grid container={true} columnSpacing={2} component={"form"}>
                  <Grid item={true} xs={8}>
                    <NGInputLabel label={t("common.Company Name")} required={true}/>
                    <Controller
                      control={patternSettingForm.control}
                      name={"companyName"}
                      render={({ field, fieldState }) => (
                        <TextField
                          required={true}
                          size="small"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                          {...field}
                          InputLabelProps={{
                            shrink: !!field.value,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} columnSpacing={1} component={"form"}>
                  <Grid item={true} xs={8}>
                    <NGInputLabel label={t("common.Contact Person")} required={true}/>
                    <Controller
                      control={patternSettingForm.control}
                      name={"contactPerson"}
                      render={({ field, fieldState }) => (
                        <TextField
                          size="small"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                          {...field}
                          InputLabelProps={{
                            shrink: !!field.value,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} columnSpacing={2} component={"form"}>
                  <Grid item={true} xs={8}>
                    <NGInputLabel label={t("organ.Country")} required={true} />
                    <Autocomplete
                      value={country_name.find((country_list) => {
                        if (!country) {
                          setCountry('USA')
                        }
                        return country_list.value === country;
                      })}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth={true}
                          size={"small"}
                        />
                      )}
                      getOptionLabel={(option) => {
                        return option.value;
                      }}
                      options={country_name}
                      onChange={(event, value) => {
                        patternSettingForm.setValue(
                          "country",
                          value?.value || ''
                        );
                        setCountry(value?.value || '')
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} columnSpacing={2} component={"form"} style={{marginTop: "20px"}}>
                  <Grid item={true} xs={8}>
                    <NGInputLabel
                      label={t("common.Email Address")}
                      required={true}
                    />
                    <Controller
                      control={patternSettingForm.control}
                      name={"emailAddress"}
                      render={({ field, fieldState }) => (
                        <TextField
                          required={true}
                          size="small"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                          {...field}
                          InputLabelProps={{
                            shrink: !!field.value,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} columnSpacing={2} component={"form"}>
                  <Grid item={true} xs={8}>
                    <NGInputLabel label={t("common.Contact Number")} required={true}/>
                    <Controller
                      control={patternSettingForm.control}
                      name={"contactNumber"}
                      render={({ field, fieldState }) => (
                        <TextField
                          size="small"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                          {...field}
                          InputLabelProps={{
                            shrink: !!field.value,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} columnSpacing={2} component={"form"}>
                  <Grid item={true} xs={8}>
                    <NGInputLabel label={t("common.Summary")} required={true} />
                    <Controller
                      control={patternSettingForm.control}
                      name={"summary"}
                      render={({ field, fieldState }) => (
                        <TextField
                          sx={{ width: "454px" }}
                          multiline
                          required={true}
                          minRows={5}
                          maxRows={5}
                          type="text"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                          {...field}
                          InputLabelProps={{
                            shrink: !!field.value,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} columnSpacing={2} component={"form"}>
                  <Grid item={true} xs={8}>
                    <NGInputLabel
                      label={t("common.Description")}
                      required={true}
                    />
                    <Controller
                      control={patternSettingForm.control}
                      name={"description"}
                      render={({ field, fieldState }) => (
                        <TextField
                          sx={{ width: "454px" }}
                          multiline
                          required={true}
                          minRows={5}
                          maxRows={5}
                          type="text"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                          {...field}
                          InputLabelProps={{
                            shrink: !!field.value,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container={true}
                  columnSpacing={2}
                  component={"form"}
                  // style={{ marginTop: "-20px" }}
                >
                  <Grid item={true} xs={8}>
                    <NGInputLabel label={t("common.Impact")} required={true} />
                    <Controller
                      control={patternSettingForm.control}
                      name={"impact"}
                      render={({ field, fieldState }) => (
                        <Select {...field} size={"small"} fullWidth={true}>
                          <MenuItem value={"outage"}>
                            {"Extensive/Outage"}
                          </MenuItem>
                          <MenuItem value={"critical"}>
                            {"Significant/ Critical"}
                          </MenuItem>
                          <MenuItem value={"high"}>{"Moderate/ High"}</MenuItem>
                          <MenuItem value={"medium"}>
                            {"Minor/ Medium"}
                          </MenuItem>
                          <MenuItem value={"low"}>{"LOW"}</MenuItem>
                        </Select>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container={true}
                  columnSpacing={2}
                  component={"form"}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item={true} xs={8}>
                    <NGInputLabel
                      label={t("common.Frequency")}
                      required={true}
                    />
                    <Controller
                      control={patternSettingForm.control}
                      name={"frequency"}
                      render={({ field, fieldState }) => (
                        <Select {...field} size={"small"} fullWidth={true}>
                          <MenuItem value={"always"}>{"Always"}</MenuItem>
                          <MenuItem value={"usually"}>{"Usually"}</MenuItem>
                          <MenuItem value={"sometimes"}>{"Sometimes"}</MenuItem>
                          <MenuItem value={"occasionally"}>
                            {"Occasionally"}
                          </MenuItem>
                          <MenuItem value={"seldom"}>{"Seldom"}</MenuItem>
                          <MenuItem value={"once"}>{"Once"}</MenuItem>
                        </Select>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} columnSpacing={2} component={"form"} style={{marginTop: "20px"}}>
                  <Grid item={true} xs={12}>
                    <NGInputLabel
                      label={t("common.Steps to Reproduce")}
                      required={true}
                    />
                    <Controller
                      control={patternSettingForm.control}
                      name={"steps2reproduce"}
                      render={({ field, fieldState }) => (
                        <TextField
                          multiline
                          minRows={9.5}
                          maxRows={9.5}
                          type="text"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                          {...field}
                          InputLabelProps={{
                            shrink: !!field.value,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} columnSpacing={2} component={"form"}>
                  <Grid item={true} xs={12}>
                    <NGInputLabel label={t("common.Attachment")} />
                    <Controller
                      control={patternSettingForm.control}
                      name={"attachment"}
                      render={({ field, fieldState }) => (
                        <div>
                          <DropzoneArea
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            dropzoneText={t(
                              "common.Drag and drop a file here or click"
                            )}
                            useChipsForPreview
                            previewGridProps={{
                              container: { spacing: 1, direction: "row" },
                            }}
                            dropzoneClass="supportDropzone"
                            previewText={t("common.Selected files")}
                            Icon={customIcon()}
                            // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                            // maxFileSize={10485760}
                            onChange={(files) => {
                              setValue("attachment", files);
                            }}
                            getFileAddedMessage={(fileName) => {
                              return t("common.File fileName successfully added.", {fileName: fileName})
                            }}
                            getFileRemovedMessage={(fileName) => {
                              return t("common.File fileName removed.", {fileName: fileName})
                            }}
                            getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                              return t('common.File fileName was rejected.', {fileName: rejectedFile?.name})
                            }}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{
              borderTop: "1px solid #F2F5F8",
              height: "60px",
              width: "100%",
              paddingLeft: "24px",
              boxSizing: "border-box",
            }}
          >
            <Grid style={{ paddingTop: "12px" }}>
              <Stack spacing={3} direction={"row"} justifyContent="start">
                <CancelButton
                  onClick={() => {
                    setOpen(false);
                    setGetData(false)
                  }}
                />
                <SavingButton
                  loading={isLoading}
                  children={TranslationUtil.t("common.confirm")}
                  sx={{ width: "160px", textTransform: "none" }}
                  onClick={async () => {
                    if (!getValues().country) {
                      setValue('country', 'USA')
                    }
                    const isValid = await trigger();
                    if (isValid) {
                      const data = getValues();
                      await onSubmit(data);
                    }
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </Box>
  );
};
export default SupportMenuItem;
