import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Typography,
} from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { KeyboardArrowRight, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SupportMenuItem from "@/parts/Header/components/SupportMenuItem";
import ResetPasswordItem from "@/pages/user/reset/index";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dialog from "@/components/dialog";
import CompanyService from "@/services/auth/CompanyService";
import UserService from "@/services/auth/UserService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import CommonUtils from "@/utils/CommonUtils";
import WebURL from "@/urls";

type MenuItemProps = {
  hidePopup?: () => void;
};

const ProfileItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const viewProfile = () => {
    navigate("/user/profile");
  };
  return (
    <Box>
      <MenuItem
        onClick={async () => {
          await props.hidePopup?.();
          setOpen(true);
          viewProfile();
        }}
      >
        <ListItemText>{t("common.Profile")}</ListItemText>
      </MenuItem>
    </Box>
  );
};

const About: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const viewProfile = () => {
    navigate("/user/about");
  };
  return (
    <Box>
      <MenuItem
        onClick={async () => {
          await props.hidePopup?.();
          setOpen(true);
          viewProfile();
        }}
      >
        <ListItemText>{t("common.About")}</ListItemText>
      </MenuItem>
    </Box>
  );
};

// const ResetPasswordItem: React.FC<MenuItemProps> = (props) => {
//   const { t } = useTranslation();
//   const [open, setOpen] = useState<boolean>(false);
//   const navigate = useNavigate();
//   const viewProfile = () => {
//     navigate("/user/reset_password");
//   };
//   return (
//     <Box>
//       <MenuItem
//         onClick={async () => {
//           await props.hidePopup?.();
//           setOpen(true);
//           viewProfile();
//         }}
//       >
//         <ListItemText>{t("common.Reset Password")}</ListItemText>
//       </MenuItem>
//     </Box>
//   );
// };

const UserProfile: React.FC = () => {
  const user = useSelector((state: any) => state.app.user);
  // console.log({ user });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "userProfile",
  });
  const handleDeleteAccount = () => {

    Dialog.confirm({
      title: t("common.Are you sure?"),
      content: t('organ.You want to delete account permanently'),
      onConfirm: async () => {
        // setLoading(true);
        const { data } = await CompanyService.companyList({ current: 1, pageSize: 50, toggleKeyToURL: true });
        if (data.total > 0) {
          // setLoading(false);
          Dialog.ok({
            content: t('common.The account still has organization. Before deleting the account, you must manually remove organization after loging firstly.'),
          })
        } else {
          UserService.deleteUser().then(res => {
            SnackbarUtil.success(t("common.success"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
            CommonUtils.setCookie("autoUserInfo", "", 0);
            navigate(WebURL.AUTH_USER_LOGIN);
          })
        }
      },
    })
  }
  return (
    <Box>
      <Tooltip title={t("common.User Profile")}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            height: "32px",
            backgroundColor: "#ffffff",
            padding: "0px",
            borderRadius: "16px",
          }}
          {...bindTrigger(popupState)}
        >
          {/* <IconButton sx={{ p: 0 }}>
            <AvatarIcon name={user?.firstName ? user.firstName : "None"} profilePhoto={user?.imagePreSignedURL ? user.imagePreSignedURL : "None"}/>
          </IconButton> */}

          <Grid
            sx={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #e3e3e3",
              borderRadius: "50%",
              cursor:'pointer',
            }}
          >
            <Avatar
              sx={{ width: "26px", height: "26px" }}
              src={user?.imagePreSignedURL ? user.imagePreSignedURL : "None"}
            />
          </Grid>

          <Typography
            sx={{
              cursor: "pointer",
              fontSize: "14px !important"
            }}
            padding={"8px"}
          >
            {user?.firstName}
          </Typography>

            <ArrowDropDownIcon
              sx={{
                fontSize: { xs: "14px", lg: "28px" },
                margin: { xs: "2px 4px 4px 4px", lg: "2px 4px 4px 4px" },
              }}
            ></ArrowDropDownIcon>
        </Grid>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        {...bindMenu(popupState)}
      >
        <SupportMenuItem hidePopup={async () => popupState.close()} />
        <ProfileItem hidePopup={async () => popupState.close()} />
        <About hidePopup={async () => popupState.close()} />
        <ResetPasswordItem hidePopup={async () => popupState.close()} />
        <Box>
          <MenuItem
            onClick={() => {
              handleDeleteAccount()
            }}
          >
            <ListItemText>{t("login.Delete Account")}</ListItemText>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};
export default UserProfile;
