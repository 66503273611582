import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, styled } from "@mui/system";
import BackGo from "@/components/back-go/BackGo";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Avatar,
} from "@mui/material";
import { DMSEndpoints } from "@/services/dms/endpoints";
import GenericService from "../../../../services/GenericService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import SnackbarUtil from "@/utils/SnackbarUtil";
import GlobalAutocomplete from "@/components/scroll-select/GlobalAutoComplete";
import { LocationEndpoints } from "@/services/location/endpoints";
import { ngteco_timezones, dst_key } from "@/components/resource/timezone";
import { object, string, TypeOf, z } from "zod";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getOrganSites } from "@/store/slices/Site";
import { getOrganZones } from "@/store/slices/Zone";
import ControlTextFieldReadOnly from "@/components/hook-form/ControlTextFieldReadOnly";
import ControlTextField from "@/components/hook-form/ControlTextField";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import SiteService from "@/services/location/SiteService";
import DeviceService from "@/services/dms/DeviceService";
import { CancelButton, SaveButton } from "@/components/data-grid/CustomButton";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";
import ZoneService from "@/services/location/ZoneService";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";

const DeviceEdit: React.FC = () => {
  const { t } = useTranslation();
  const deviceSchema = object({
    sn: z.string().nullish(),
    alias: z.string().min(1, t("common.This field is required!")),
    // site: z.object({
    //   id: z.string(),
    //   name: z.string(),
    //   address: z.object({
    //     timeZone: z.string().nullish()
    //   })
    // }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    // zone: z.object({
    //   id: z.string(),
    //   name: z.string()
    // }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    timezone: z.union([
      z.string().nullish(),
      z
        .object({
          value: z.string(),
          name: z.string(),
        })
        .nullish(),
    ]),
    model: z.string(),
    category: z.string(),
    protocol_type: z.string(),
    status: z.string(),
    MCUVersion: z.string().nullish(),
    firmware_ver: z.string().nullish(),
    ipv4: z.string().nullish(),
    organization_name: z.string().nullish(),
  });
  type deviceFormType = TypeOf<typeof deviceSchema>;

  const location = useLocation();
  const state = location.state as { id: string };
  const navigate = useNavigate();
  const sitesInitial: {
    id: string;
    name: string;
    address: Record<string, any>;
  }[] = useSelector((state: any) => state.site.sites);
  const zonesInitial: { id: string; name: string }[] = useSelector(
    (state: any) => state.zone.zones
  );
  const timezoneList = ngteco_timezones();
  const dst_list = dst_key();
  const [timeZone, setTimeZone] = useState<string>();
  const [timezoneEmpty, setTimezoneEmpty] = useState<boolean>(false);
  const [oldData, setOldData] = useState<any>();
  const deviceForm = useForm<deviceFormType>({
    resolver: zodResolver(deviceSchema),
    defaultValues: {
      alias: "",
      sn: "",
      // site: {id: "", name: ""},
      // zone: {id: "", name: ""},
      timezone: "",
      model: "",
      category: "",
      protocol_type: "",
      status: "",
      ipv4: "",
      MCUVersion: "",
      firmware_ver: "",
      organization_name: "",
    },
  });
  const [siteInputValue, setSiteInputValue] = useState<any>("");
  const [siteValue, setSiteValue] = useState<any>(null);
  const [selectSite, setSelectSite] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onEdit = async () => {
    setSaveLoading(true);
    try {
      const isValid = await deviceForm.trigger();
      if (isValid && !!siteValue) {
        setSelectSite(true);
        const values = deviceForm.getValues();
        const dst_id = dst_list.find((item) => item.value === siteValue.address.timeZone)
        const origin = {
          deviceAlias: values.alias,
          siteId: siteValue.id,
          siteName: siteValue.name,
          dstKey: dst_id?.name || "ngteco",
        };
        const data = {
          ...origin,
          oldData: oldData,
        };
        const site_res = timezoneEmpty
          ? await SiteService.update(siteValue.id, {
            address: {
              timeZone,
              city: "default",
              addressLine1: "default",
              siteId: siteValue.id,
            },
          })
          : {};
        const device_res = await DeviceService.update(state.id || "", data);
        SnackbarUtil.success(t("common.success"));
        navigate(WebURL.DMS_DEVICE);
      } else {
        !siteValue && setSelectSite(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSaveLoading(false);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getOrganSites());
  }, []);
  useEffect(() => {
    GenericService.retrieve(DMSEndpoints.DMS_DEVICE_URI, state.id || "").then(
      (res) => {
        const device = res.data;
        const site = sitesInitial.find((item) => item.id === device.site);
        const timezone = timezoneList.find(
          (item) => item.value === site?.address?.timeZone
        );
        deviceForm.reset({
          // site: site || {id: device.site, name: device.site_name},
          timezone: timezone?.name,
          alias: device.alias,
          sn: device.sn,
          model: device.model,
          ipv4: device.ipv4 || "",
          status: device.status === "1" ? t("dms.Online") : t("dms.Offline"),
          category: device.category,
          protocol_type: device.protocol_type,
          MCUVersion: device.MCUVersion,
          firmware_ver: device.firmware_ver,
          organization_name: device.organization_name,
        });
        setOldData({
          deviceAlias: device.sn,
          siteName: device.site_name,
        });
        if (device.site) {
          setSiteValue(site || { id: device.site, name: device.site_name, timezone: device.timezone });
          setSiteInputValue(device.site_name);
        }
      }
    );
  }, [sitesInitial, zonesInitial]);

  return (
    <>
      <Grid
        sx={{
          boxSizing: "border-box",
          marginRight: "24px",
        }}
      >
        <BackGo title={t("common.Device information")}></BackGo>
      </Grid>
      <Paper
        sx={{
          padding: "20px",
          boxSizing: "border-box",
          marginRight: "24px",
          marginTop: "16px",
          minHeight: "calc(100vh - 180px)",
        }}
      >
        <CustomBreadcrumbs
          record={[
            // { path: WebURL.DMS_DEVICE, label: t("common.Device") },
            { label: t("common.Edit device") },
          ]}
        />
        {/* <Box style={{marginTop: "1vh"}}>
        <Typography>{t("common.Device information")}</Typography>
      </Box> */}
        <Paper
          elevation={0}
          style={{
            minHeight: "200px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            paddingTop: "16px",
          }}
        >
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <Typography>{t("common.Device Model")}</Typography>
              <ControlTextFieldReadOnly
                label=""
                form={deviceForm}
                name="model"
              />
            </Grid>
            <Grid item xs={4}>
              <Grid sx={{display: "flex"}}>
                <Typography>
                  {t("common.Device Alias")}
                </Typography>
                <Typography color={"red"}>
                  *
                </Typography>
              </Grid>
              <ControlTextField label="" name="alias" form={deviceForm} />
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("common.Serial Number")}</Typography>
              <ControlTextFieldReadOnly label="" form={deviceForm} name="sn" />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <Typography>{t("common.IP Address")}</Typography>
              <ControlTextFieldReadOnly
                label=""
                form={deviceForm}
                name="ipv4"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("common.Bind Organization")}</Typography>
              <ControlTextFieldReadOnly
                label=""
                form={deviceForm}
                name="organization_name"
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={sitesInitial}
                loading={loading}
                value={siteValue}
                onChange={(_, value) => {
                  const timezone = value?.address.timeZone;
                  if (timezone) {
                    deviceForm.setValue(
                      "timezone",
                      timezoneList.find((item) => item.value === timezone)
                        ?.name || `UTC${timezone}`
                    );
                    setTimezoneEmpty(false);
                    setTimeZone(undefined);
                  } else {
                    setTimezoneEmpty(true);
                  }
                  setSiteValue(value);
                }}
                inputValue={siteInputValue}
                onInputChange={(_, v) => setSiteInputValue(v)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <>
                    <NGInputLabel label={t("dms.Bind Site")} required={true} />
                    <TextField
                      {...params}
                      // label={t("dms.Bind Site")}
                      required={true}
                      error={!selectSite}
                      helperText={(!selectSite && "Required") || " "}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "false",
                        endAdornment: (
                          <React.Fragment>
                            {loading && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              {timezoneEmpty ? (
                <ControlAutoComplete
                  label={t("common.Site Time zone")}
                  name={"timezone"}
                  form={deviceForm}
                  options={timezoneList}
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value;
                  }}
                  onChange={({ value }) => {
                    setTimeZone(value);
                  }}
                  required
                />
              ) : (
                <>
                  <Typography>{t("common.Site Time zone")}</Typography>
                  <ControlTextFieldReadOnly
                    label=""
                    name="timezone"
                    form={deviceForm}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Paper>

        <Box style={{ padding: "10px 0 0 0" }}>
          <Typography variant='h6'>{t("common.Firmware Information")}</Typography>
        </Box>
        <Paper
          elevation={0}
          style={{
            minHeight: "120px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            paddingTop: "16px",
          }}
        >
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <Typography>{t("common.Protocol Type")}</Typography>
              <ControlTextFieldReadOnly
                label=""
                form={deviceForm}
                name="protocol_type"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("common.Firmware Version")}</Typography>
              <ControlTextFieldReadOnly
                label=""
                form={deviceForm}
                name="firmware_ver"
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid style={{ padding: "35px" }}>
          <Stack spacing={3} direction={"row"} justifyContent="flex-end">
            <CancelButton />
            <SaveButton onClick={deviceForm.handleSubmit(onEdit)} />
          </Stack>
        </Grid>
      </Paper>
    </>
  );
};
export default DeviceEdit;
