export const deepObjectMerge = (target, ...objects) => {
  target = target || {}
  let len = objects.length
  for (let i = 0; i < len; i++) {
    let SecondOBJ = objects[i]
    for (var key in SecondOBJ) {
      target[key] =
        target[key] && target[key].toString() === '[object Object]'
          ? deepObjectMerge(target[key], SecondOBJ[key])
          : (target[key] = SecondOBJ[key])
    }
  }
  return target
}

export function init(src, id, snippetId, options = {}) {
  src =
    'https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js'
  id = 'c7b4ac06-63c4-4026-bdc7-d637458d4b2e'
  snippetId =
    'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdFTzByV1laN21MQ3BsVnBlbEZtcWJZQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNVHMyWFpjNnJBd0hEZVMzcUFnRVFnQ3ZoUXFOMVk2c1dLeDYzZ3l4Z1FvalpYNVpaMGVScEJPcTl4OHIxUk5WTmMzTW9WekFReXRBd0l4ZTY6Okl4NFdZMHF4YlR4QkVBZDdOZ3Z4SmR4V09HcndtL28yWC90bTZGa2QwMUpoVkp0SWZCSDdPdW5IbThkYU8wQ2lhV1pkZzdJM3Z1QU5vWGZxdDlkQmtrYmRGVzlTR0w0ZnZSaXJVT2NDVzRhMlkwRk9EYjRXU1E3UFkxN0kvN09RYi9CTEZxK01tSzN4a3Zndjh1R1haM3RIcDdUREE3OD0='
  if (!src) {
    console.error('请输入src')
  }

  if (!id) {
    console.error('请输入Id')
  }

  if (!snippetId) {
    console.error('请输入snippetId')
  }

  (function (w, d, x, id) {
    let s = d.createElement('script')
    s.src = src
    s.async = 1
    s.id = id
    d.getElementsByTagName('head')[0].appendChild(s)
    w[x] =
      w[x] ||
      function () {
        (w[x].ac = w[x].ac || []).push(arguments)
      }
  })(window, document, 'amazon_connect', id)

  let styles = {
    iconType: 'CHAT_VOICE',
    openChat: { color: '#ffffff', backgroundColor: '#245bff' },
    closeChat: { color: '#ffffff', backgroundColor: '#484e56' },
  }
  deepObjectMerge(styles, options.styles || {})
  let defaultContentTypes = [
    'text/plain',
    'text/markdown',
    'application/vnd.amazonaws.connect.message.interactive',
    'application/vnd.amazonaws.connect.message.interactive.response',
  ]
  let mergedArray = Array.from(
    new Set([
      ...defaultContentTypes,
      ...(options.supportedMessagingContentTypes || []),
    ])
  )

  let amazon_connect = window.amazon_connect
  amazon_connect('styles', styles)
  amazon_connect('snippetId', snippetId)
  amazon_connect('supportedMessagingContentTypes', mergedArray)

  amazon_connect('customerDisplayName', function (callback) {
    const displayName = 'Customer'
    callback(displayName)
  })

  let customStyles = {
    global: {
      frameWidth: '50vw',
      frameHeight: '90vh',
      fontSize: '16px',
      footerHeight: '100px',
      typeface: 'Trebuches MS',
    },
    header: {
      headerTextColor: '#0c0c0c',
      headerBackgroundColor: '#ffffff',
    },
    footer: {
      buttonFontSize: '13px',
      buttonTextColor: '#059ad9',
      buttonBorderColor: '#f1f1f1',
      buttonHoler: '#f1f1f1',
      buttonBackgroundColor: '#ffffff',
      backgroundColor: '#0b2200',
      footerBackgroundColor: '#f1f1f1',
      startCallButtonTextColor: '#ffffff',
      counter_revolutionaries: '#0b2200',
      startChatButtonBorderColor: '#059ad9',
      startCallButtonBackgroundColor: '#059ad9',
    },
    logo: {
      logoMaxHeight: '30px',
      logoMaxWidth: '100px',
    },
    transcript: {
      customerChatBubbleColor: "#f1f1f1",
    }
  }
  amazon_connect('customDisplayNames', {
    header: {
      headerMessage: "Welcome!",
      // logUrl: "https://img.alicdn.com/imgextra/i4/O1CN01HGhduk1a3XUaa2jbf_!!6000000003274-2-tps-198-200.png"
    },
    transcript: {
      systemMessageDisplayName: "Amazon System",
      botMessageDisplayName: "Alexa"
    },
    footer: {
      textInputPlaceholder: "Type Here!",
      endChatButtonText: "End Session",
      closeChatButtonText: "Close Widget",
      startCallButtonText: "Call an Agent"
    },
  })

  deepObjectMerge(customStyles, options.customStyles || {})

  amazon_connect('customStyles', customStyles)


  //https://docs.aws.amazon.com/zh_cn/connect/latest/adminguide/pass-customization-object.html
  let customizationObject = {
    header: {
      dropdown: false,
      dynamicHeader: true,
      hideTranscriptDownloadButton: true,
    },
    transcript: {
      hideDisplayNames: false,
      eventNames: {
        customer: 'customer',
        agent: 'Webchat Agent',
        supervisor: 'Webchat Supervisor',
      },
      displayIcons: true,
      eventMessages: {
        participantJoined: '{name} has joined the chat',
        participantDisconnect: '',
        participantLeft: '{name}',
        participantIdle: '{name}, are you still there?',
        participantReturned: '',
        chatEnded: 'Chat ended',
      },
      iconSources: {
        // botMessage: system_image,
        // systemMessage: system_image,
        // 'https://img.alicdn.com/imgextra/i4/O1CN01HGhduk1a3XUaa2jbf_!!6000000003274-2-tps-198-200.png',
        agentMessage:
          'https://img.alicdn.com/imgextra/i4/O1CN01HGhduk1a3XUaa2jbf_!!6000000003274-2-tps-198-200.png',
        // customerMessage: user_image,
      },
    },
    composer: {
      disableEmojiPicker: true,
      disableCustomerAttachments: true,
    },
    footer: {
      disabled: false,
      skipCloseChatButton: true
    },
  }
  deepObjectMerge(customizationObject, options.customizationObject || {})
  amazon_connect('customizationObject', customizationObject)

  let customLaunchBehavior = {
    skipIconButtonAndAutoLaunch: true,
    alwaysHideWidgetButton: true,
    programmaticLaunch: function (launchCallback) {
      var launchWidgetBtn = document.getElementById('launch-widget-btn')
      if (launchWidgetBtn) {
        launchWidgetBtn.addEventListener('click', launchCallback)
        window.onunload = function () {
          launchWidgetBtn.removeEventListener('click', launchCallback)
          return
        }
      } else {
        window.onunload = function () {
          launchWidgetBtn.removeEventListener('click', launchCallback)
          return
        }
      }
    },
  }
  deepObjectMerge(customLaunchBehavior, options.customLaunchBehavior || {})
  //
  amazon_connect('customLaunchBehavior', customLaunchBehavior)
  // var style = document.createElement('style')
  // 添加CSS规则
  // style.innerHTML = 'body {background-color: #bf0808;}'
  // 将<style>元素添加到<head>中
  // document.head.appendChild(style)
}
