import React, { useState, useEffect, useRef } from 'react';
import Fab from '@mui/material/Fab';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import {init} from "@/components/AiChatBot";
import systemImage from "@/assets/icons/ngteco-office.svg";
import {useSelector} from "react-redux";

function DraggableFloatingButton() {
  const user = useSelector((state: any) => state.app.user);
  const [position, setPosition] = useState({ x: window.innerWidth - 1000, y: window.innerHeight - 800 });
  const [dragging, setDragging] = useState(false);
  const isInit = useRef(false)
  const handleMouseDown = (e: any) => {
    setDragging(true);
    e.preventDefault();
  };

  const handleMouseMove = (e: any) => {
    if (dragging) {
      setPosition({
        x: e.clientX - 25, // 减去按钮宽度的一半
        y: e.clientY - 25, // 减去按钮高度的一半
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };
  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true
      init('https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js',
        'c7b4ac06-63c4-4026-bdc7-d637458d4b2e',
        'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdFTzByV1laN21MQ3BsVnBlbEZtcWJZQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNVHMyWFpjNnJBd0hEZVMzcUFnRVFnQ3ZoUXFOMVk2c1dLeDYzZ3l4Z1FvalpYNVpaMGVScEJPcTl4OHIxUk5WTmMzTW9WekFReXRBd0l4ZTY6Okl4NFdZMHF4YlR4QkVBZDdOZ3Z4SmR4V09HcndtL28yWC90bTZGa2QwMUpoVkp0SWZCSDdPdW5IbThkYU8wQ2lhV1pkZzdJM3Z1QU5vWGZxdDlkQmtrYmRGVzlTR0w0ZnZSaXJVT2NDVzRhMlkwRk9EYjRXU1E3UFkxN0kvN09RYi9CTEZxK01tSzN4a3Zndjh1R1haM3RIcDdUREE3OD0=',
        {})
    }
  }, [])

  return (
    <div
      // onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
      style={{ height: '100vh', position: 'relative' }}
      id='launch-widget-btn'
    >
      <Fab
        color="primary"
        aria-label="add"
        style={{
          position: 'absolute',
          // left: "80vw",
          left: window.innerWidth - 400,
          // left: "95vw",
          // bottom: window.innerHeight - 850,
          bottom: "10vh",
          backgroundColor: '#245bff',
          // cursor: dragging ? 'grabbing' : 'grab',
        }}
        // onMouseDown={handleMouseDown}
      >
        <TextsmsOutlinedIcon/>
      </Fab>
    </div>
  );
}

export default DraggableFloatingButton;
