import CompanyService from "@/services/auth/CompanyService";
import WebURL from "@/urls";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {useSnackbar} from "notistack";
import Dialog from "@/components/dialog";
import UserService from "@/services/auth/UserService";
import CommonUtils from "@/utils/CommonUtils";

interface ConfirmProps {
  setStep: Dispatch<SetStateAction<string>>,
  company: Record<string, any>
}

const Confirm: React.FC<ConfirmProps> = (props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar()
  const handleDeleteAccount = (company_name: string) => {
    Dialog.confirm({
      title: t("common.Are you sure?"),
      content: t('organ.You want to delete account permanently'),
      onConfirm: async () => {
        // setLoading(true);
        await CompanyService.destroy(company_name).then(res => {
          SnackbarUtil.success(t('organ.destroy.Delete succcesfully'), {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        })
        await UserService.deleteUser().then(res => {
          // SnackbarUtil.success(t("common.success"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
          CommonUtils.setCookie("autoUserInfo", "", 0);
        })
        navigate(WebURL.AUTH_USER_LOGIN)
      },
    })
  }

  const onConfirm = async () => {
    setLoading(true);
    try {
      const company_name = encodeURIComponent(props.company.code)
      const def_data = await CompanyService.defaultCompany({});
      if (def_data.status < 400) {
        if (def_data.company_len == 1) {
          handleDeleteAccount(company_name)
        } else {
          await CompanyService.destroy(company_name).then(res => {
            SnackbarUtil.success(t('organ.destroy.Delete succcesfully'), {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
          })
          navigate(WebURL.AUTH_USER_LOGIN);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box>
        <Grid sx={{margin: '8px 0'}}>
          {t("organ.destroy.confirmTip01")}
        </Grid>
        <Grid sx={{margin: '8px 0'}}>
          {t("organ.destroy.confirmTip02")}
        </Grid>
        <Grid sx={{margin: '8px 0'}}>
          {t("organ.destroy.confirmTip03")}
        </Grid>
        <Grid sx={{margin: '8px 0'}}>
          {t("organ.destroy.confirmTip04")}
        </Grid>
        <Grid>
          <Typography sx={{fontWeight: 'bold'}}>
            <Trans
              i18nKey={"organ.destroy.confirmTip05"}
              values={{ code: props.company.code }}
              components={[<Grid sx={{display: 'inline', padding: '2px 4px', color: '#C7254E', backgroundColor: "#F9F2F4"}}></Grid>]}
            />
          </Typography>
          <TextField
            fullWidth
            onChange={(event) => {
              if (event.target.value == props.company?.code) {
                setConfirm(true);
              } else {
                setConfirm(false);
              }
            }}
          />
        </Grid>
      </Box>
      <Box sx={{ position: 'absolute', bottom: '20px', width: '500px'}}>
        <LoadingButton
          disabled={!confirm}
          sx={{ width: '100%', padding: "10px", textTransform: "capitalize"}}
          variant="contained"
          onClick={onConfirm}
          loading={loading}
        >
          {t('organ.destroy.Delete confirm')}
        </LoadingButton>
      </Box>
    </>
  )
}

export default Confirm;
